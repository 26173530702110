
import {Image, Button} from "react-bootstrap";
import {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import hero from "../assets/images/hero.png";
import birdStack from "../assets/images/bird_stack.png";
import getEfficient from "../assets/images/get_efficient.png";
import maintenance from "../assets/images/maintenance.png";
import docCreation from "../assets/images/document_creation.png";
import logoWall from "../assets/images/logo_wall.png";
import number1 from "../assets/images/1.png";
import number2 from "../assets/images/2.png";
import number3 from "../assets/images/3.png";
import seal from "../assets/images/seal.png";
import bird from "../assets/images/bird.png";
import ThreeColumn from "../components/ThreeColumn";
import TwoColumnImageRight from "../components/TwoColumnImageRight";

function Home() {
    const [videoUrl] = useState("https://www.youtube.com/embed/Q0Dr1VWWBYc?si=Eri-kDEb5_0ErPrc");
    return (
        <>
            {/* Hero*/}
            <Container fluid className="main">
                {/* Hero Section Md and up*/}
                <Row>
                    <Col
                        md="12"
                        className="d-none d-md-block"
                        style={{
                            height: "632px",
                            backgroundImage: `url(${hero})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <h5>INTRODUCTION</h5>
                        <h1>Information Organized</h1>
                        <p key="text1" style={{width: "50%"}}>
                            Efficient is exactly that.<br/>
                            An efficient, game-changing information organization system and service that will streamline the way your
                             business sees, stores, accesses, updates and shares – everything.
                        </p>
                        <Button variant="danger" href="/about">
                            Learn More
                        </Button>
                    </Col>
                </Row>

                {/* Hero Section Small Screens*/}
                <Row>
                    <Col
                        md="12"
                        className="d-block d-md-none"
                    >
                        <h5>INTRODUCTION</h5>
                        <h1>Information Organized</h1>
                        <p key="text1">
                            Efficient is exactly that.<br/>
                            An efficient, game-changing information organization system and service that will streamline
                             the way your business sees, stores, accesses, updates and shares – everything.
                        </p>
                        <Button variant="danger" href="/about">
                            Learn More
                        </Button>
                        <Image
                            src={hero}
                            style={{width: "100%"}}
                        />
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <p key="text1">
                            All your documents, digital assets and IP – across every department and function – are organised
                             and managed via an automated master index, all on your existing servers.
                        </p>
                        <p key="text2">
                            Efficient brings greater clarity, focus and forward planning. It declutters desktops and head spaces,
                             creates certainty and confidence. The Efficient system quite simply creates a better business.
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* VIDEO Desktop */}
            <Container
                className="text-center d-none d-md-block"
                style={{marginTop: "-40px", paddingBottom: "20px"}}
            >
                <h1>What we do</h1>
                <iframe
                    title="Welcome to Efficient"
                    src={videoUrl}
                    width="854"
                    height="480"
                    allow="autoplay; fullscreen;"
                    allowFullScreen
                    style={{
                        borderRadius: "15px",
                    }}
                >
                </iframe>

            </Container>
            {/* VIDEO Mobile */}
            <Container
                className="text-center d-block d-md-none"
                style={{
                    marginTop: "-40px",
                    paddingBottom: "20px",
                }}
            >
                <iframe
                    title="Welcome to Efficient"
                    src={videoUrl}
                    width="360"
                    height="203"
                    allow="autoplay; fullscreen;"
                    allowFullScreen
                    style={{
                        borderRadius: "15px",
                    }}
                >
                </iframe>
            </Container>

            <TwoColumnImageRight
                smallTitle="QUIZ"
                title="How Efficient are you?"
                body={[
                    "If you have the time, this efficient little quiz will give you a good idea of some organisational tricks you may be missing.",
                ]}
                col1Image={birdStack}
                link="/quiz"
                buttonText="Take the Quiz"
                classes="main pink"
            />

            <ThreeColumn
                smallTitle="SERVICES"
                title="What we offer"
                body={"Efficient will transform the way your business handles information, making it easy to access the current version of anything."
                        +" Here’s how we do it."}
                col1Image={getEfficient}
                col1Title="Get Efficient"
                col1Text={"This is our foundation service where we design your Efficient index, structure your"
                        +" folders and documents, and train your team."}
                col2Image={maintenance}
                col2Title="Efficient Maintenance"
                col2Text={"We make it easy and cost-effective to maintain your Efficient system using smart (SaaS)"
                +" software and monthly support from Efficient Gurus."}
                col3Image={docCreation}
                col3Title="Document Creation"
                col3Text={"When you need to create a new template, procedure or manual from scratch, we can design"
                +" something logical that your team can pick up and start using."}
            />

            {/* Clients'*/}
            <Container fluid className="main yellow text-center d-none d-md-block">
                <Row>
                    <Col sm="2"/>
                    <Col sm="8" >
                        <h5>EFFICIENT CLIENTS</h5>
                        <h1>Who we&apos;ve worked with</h1>
                    </Col>
                    <Col sm="2"/>
                </Row>
                <Row>
                    <Col sm="12">
                        <Image
                            src={logoWall}
                            style={{maxWidth: "95%"}}
                        />
                    </Col>
                </Row>
            </Container>

            {/* Testimonials'
            <Container fluid className="main black text-center d-none d-md-block">
                <Row>
                    <Col sm="12" >
                        <h1 className="whiteText" >Testimonials</h1>
                    </Col>
                </Row>
                <Row>
                    <p className="whiteText">Testimonials will go here.</p>
                </Row>
            </Container>
            */}

            {/* How we do it'*/}
            <ThreeColumn
                smallTitle="PROCESS"
                title="How we do it"
                body={"Getting Efficient is a straightforward, staged process designed to put minimal "
                    +"demands on your time, and enable easy implementation."}
                col1Image={number1}
                col1Title="Understanding your business"
                col1Text={"We meet with the business or organization head to get a first-hand overview of your "
                    +"pain points and potential growth areas. Then we create a draft for your Efficient system."}
                col2Image={number2}
                col2Title="Draft Master Index"
                col2Text={"We present the proposed master index to your leadership team to ensure it captures "
                    +"the overall business and departments that need to be covered by Efficient."}
                col3Image={number3}
                col3Title="Feedback Loop"
                col3Text={"With the draft master index agreed, we then meet with key department leaders or subject"
                    +" matter experts to add specific department functions within Efficient."}
                link="/process"
            />

            {/* Certification*/}
            <Container fluid className="main yellow text-center d-none d-md-block mb-5">
                <Row>
                    <Col sm="2"/>
                    <Col sm="8" >
                        <h5>GET CERTIFIED</h5>
                        <h1>Become an Efficient Certified Organization</h1>
                        <p>
                            Becoming an Efficient Certified Organization is like a quality accreditation. It shows potential clients,
                            partners and buyers that you operate at a high standard. To become certified, your team will learn the
                            fundamentals of the Efficient document management system and document naming conventions,
                            so they can manage the system with ease.
                        </p>
                    </Col>
                    <Col sm="2"/>
                </Row>
                <Row>
                    <Col sm="12">
                        <Image
                            className="pt-5 pb-5"
                            src={seal}
                            style={{maxWidth: "300px"}}
                        />
                    </Col>
                </Row>
                <Button variant="danger" href="/certification">
                    Learn More
                </Button>
            </Container>

            {/* Case Studies
            <Container fluid className="main text-center d-none d-md-block">
                <Row className="pb-5">
                    <Col sm="2"/>
                    <Col sm="8" >
                        <h5>CASE STUDIES</h5>
                        <h1>Stories on Efficient Transformations</h1>
                        <p>
                            See how other businesses have sorted their digital files to streamline internal process,
                             build their IP, and improve product and service quality.
                        </p>
                    </Col>
                    <Col sm="2"/>
                </Row>
                <Button variant="danger" href="/case-studies">
                    View All
                </Button>
            </Container>
            */}

            {/* Contact'*/}
            <Container fluid className="blackCallout d-none d-md-block">
                <Row>
                    <Col sm="8" >
                        <h5>CONTACT US</h5>
                        <h1 className="whiteText" >Find out how to become an Efficient business.</h1>
                    </Col>
                    <Col sm="4">
                        <Image
                            className="mt-5"
                            style={{float: "right"}}
                            src={bird}
                        />
                    </Col>
                </Row>
                <Button variant="danger" href="/contact">
                    Book a FREE Consultation
                </Button>
            </Container>

            {/* Articles
            <Container fluid className="main text-center d-none d-md-block">
                <Row className="pb-5">
                    <Col sm="2"/>
                    <Col sm="8" >
                        <h5>ARTICLES</h5>
                        <h1>Efficient Insights and Hot Takes</h1>
                        <p>
                            We want to share what we know, what we’ve learned, and useful information on efficiency, productivity and managing IP.
                        </p>
                    </Col>
                    <Col sm="2"/>
                </Row>
                <Button variant="danger" href="/articles">
                    View All
                </Button>
            </Container>
            */}
        </>
    );
}

export default Home;
