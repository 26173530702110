import {Image} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactCallout from "../components/ContactCallout";
import Hero from "../components/Hero";
import OneColumn from "../components/OneColumn";
import TwoColumnAllText from "../components/TwoColumnAllText";
import ThreeColumn from "../components/ThreeColumn";
import certification from "../assets/images/certifications.png";
import stick from "../assets/images/sticks.png";
import handshake from "../assets/images/handshake.png";
import star from "../assets/images/star.png";
import certificate from "../assets/images/certificate-mockup.png";

function Certification() {
    return (
        <Container fluid className="h-100">
            <Hero
                image={certification}
                smallTitle="CERTIFICATION"
                title="Get Certified"
                body={[
                    "Efficient gives you a business advantage.",
                    <br key="text1" />,
                    "Becoming ",
                    <b key="text2">Efficient Certified</b>,
                    " signals that advantage to everyone.",
                ]}
                col1={"Becoming an Efficient Certified Organisation is like a quality accreditation."
                    +"It shows potential clients, partners and buyers that you operate at a high standard."
                    +" To become certified, your team will learn the fundamentals of the Efficient document "
                    +"management system and document naming conventions, so they can manage the system with ease."
                    +" You’ll assign at least one Efficient Manager for overall governance."}
                col2={"Training for general Efficient users takes less than half an hour, and Efficient Manager training"
                    +" requires around one hour. With your team certified, and your index established, your business achieves"
                    +" certification – renewed every two years after some simple validation you’re still operating efficiently."
                    +" Efficient Guru’s are direct from Efficient’s HQ – who can help you with this process!"}
            />

            <OneColumn
                smallTitle="STEP ONE"
                title="Your Team Become Efficient Certified Users"
                body={"Once you have signed up to be an Efficient business, your whole team learn the foundation concepts – with"
                    +" a simple recall test – to become Efficient Certified Users. They will see how the system works and how to"
                    +" navigate it. All the information they need to know is presented in an infographic and in a short video and"
                    +" interactive demo."}
                buttonText="Take the user test"
                link="/learn-certification"
                classes="main text-center"
            />

            <Container fluid className="text-center mb-5">
                <Row>
                    <Col sm="12">
                        <Image
                            src={certificate}
                            style={{
                                width: "100%",
                                maxWidth: "500px",
                            }}
                        />
                    </Col>
                </Row>
            </Container>

            <TwoColumnAllText
                smallTitle="STEP TWO"
                title="Chose an in-house Efficient Manager"
                col1Text={[
                    <p key="text1">
                        Once your company signs up to use Efficient, you choose one (or two) internal ‘owners’ to manage the system
                         for you, with our team here for support, as you get up and running. The Efficient Certified Manager course
                          only takes an hour and comprises learning videos and an infographic to study before taking the Manager test.
                    </p>,
                    <p key="text2">
                        This certification means your Efficient Certified Managers are skilled to do anything from updating documents,
                         creating new additions, and manage all the information your organisation generates – all in one place.
                          The quality of work goes up, the error rate goes down, giving staff and customers more confidence and
                           enjoyment when working with you.
                    </p>,
                ]}
                col2Text={[
                    <p key="text1">Course content includes:</p>,
                    <ul key="text2">
                        <li>Efficient Index design strategy</li>
                        <li>Setting up an effective Index</li>
                        <li>Editing an Efficient Index</li>
                        <li>Renaming an Index Document</li>
                        <li>Recalling revisions numbers</li>
                        <li>Allocating teams members & rights</li>
                    </ul>,
                    <p key="text3">
                        When an Efficient Manager graduates from the course, they are rewarded with a
                         personalised Efficient Certified Manager certificate valid for 2 years, so
                          accreditation is always fresh. Manager training is available to Efficient customers only.
                    </p>,
                ]}
                buttonText="Take the user test"
                link="/learn-certification"
                classes="main yellow"
            />

            <OneColumn
                smallTitle="STEP THREE"
                title="Efficient Guru’s are here to help!"
                body={"Through the stages of onboarding your team members, you can reach out for help from one "
                        +"of our internally trained Efficient Guru’s. They can help with any training questions, "
                        +"or even be engaged to do the work for you as part of our Services!"}
                buttonText="See Services"
                link="/services"
                classes="main text-center"
            />

            <TwoColumnAllText
                smallTitle="STEP FOUR"
                title="Establish your Index"
                col1Text={[
                    <p key="text1">
                        Once your team have completed the training and become certified,
                        the last step for certification is to set up your Efficient Document Index.
                        Our team will review it and make sure it’s the best it can be, and then you’ll
                        start to operate with greater ease and accuracy.
                    </p>,
                    <p key="text2">
                        An Efficient Certified Organisation status gives customers and
                     clients confidence in the quality of your systems. It tells them you can retrieve
                      and distribute mission-critical assets quickly and accurately.
                    </p>,
                ]}
                col2Text={[
                    <p key="text1">
                        It reassures them that your people are all on the same page, literally.
                         They know whatever they’re getting is the latest and greatest version,
                          and all revisions are captured. New employees know onboarding will be
                           quick and painless. Auditors know their job is going to be easier.
                    </p>,
                    <p key="text2">
                        Potential buyers know everything is neat and tidy under the hood, making it
                         easier for them to access true value. It’s a point of difference that’s also a talking point.
                    </p>,
                ]}
                buttonText="Try Demo"
                link="/demo"
                classes="main yellow"
            />

            <ThreeColumn
                title="Being an Efficient Certified Organization demonstrates that you"
                col1Image={stick}
                col1Title="Are highly organized"
                col2Image={handshake}
                col2Title="Are easy to deal with"
                col3Image={star}
                col3Title="Maintain high quality"
            />

            <ContactCallout/>

        </Container>
    );
}

export default Certification;
