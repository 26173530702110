import "../css/about.scss";
import {Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

function OneColumn({
    smallTitle,
    title,
    body,
    link,
    buttonText,
    classes, // main, pink, yellow,
}) {
    return (
        <>
            <Container fluid className={classes}>
                <Row>
                    <Col sm="2"/>
                    <Col sm="8" >
                        {smallTitle ? <h5>{smallTitle}</h5> : <></>}
                        <h1>{title}</h1>
                        <p>{body}</p>
                        {
                            link
                                ? <p className="pt-4">
                                    <Button variant="danger" href={link}>
                                        {buttonText}
                                    </Button>
                                </p>
                                : <></>
                        }
                    </Col>
                    <Col sm="2"/>
                </Row>


            </Container>
        </>
    );
}

OneColumn.propTypes = {
    smallTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    link: PropTypes.string,
    buttonText: PropTypes.string,
    classes: PropTypes.string.isRequired,
};

export default OneColumn;
