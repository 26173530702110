import {
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import React, {useCallback, useEffect} from "react";
import axios from "axios";
import PropTypes from "prop-types";

function ReCaptcha({
    setCaptchaVerified,
    triggerVerify,
}) {
    const baseUrl = (
        window.location.href.includes("localhost")
        || window.location.href.includes("127.0.0.1")
    )
        ? "http://localhost:3001/contact"
        : (
            "https://api."
            + window.location.hostname
            + window.location.pathname
        );
    const {executeRecaptcha} = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        const token = await executeRecaptcha("submit_contact_form");
        // Send this token to your server for verification
        axios.post(
            baseUrl+"/captcha",
            {token})
            .then((response) => {
                // Check server response here
                if (response.data.success) {
                    setCaptchaVerified(true);
                } else {
                    // Handle verification failure
                    setCaptchaVerified(false);
                }
            })
            .catch((error) => {
                setCaptchaVerified(false);
            });
    }, [baseUrl, executeRecaptcha, setCaptchaVerified]);

    useEffect(() => {
        if (triggerVerify) {
            triggerVerify(handleReCaptchaVerify);
        }
    }, [handleReCaptchaVerify, triggerVerify]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return (
        <>
        </>
    );
}

ReCaptcha.propTypes = {
    setCaptchaVerified: PropTypes.func.isRequired,
    triggerVerify: PropTypes.func.isRequired,
};

export default ReCaptcha;
