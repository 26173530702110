import "../css/about.scss";
import {Image} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import startQuote from "../assets/images/start_quote.png";
import endQuote from "../assets/images/end_quote.png";
import PropTypes from "prop-types";

function Quote({
    text,
    author,
}) {
    return (
        <>
            <Container fluid style={{marginTop: "100px", marginBottom: "100px"}}className="blackCallout d-none d-md-block">
                <Row>
                    <Col sm="12">
                        <Image
                            src={startQuote}
                            style={{width: "100px"}}
                        />
                        <h1 className="whiteText" >
                            {text}
                        </h1>
                        <Image
                            src={endQuote}
                            style={{width: "100px", float: "right"}}
                        />
                        <p className="whiteText" style={{paddingRight: "40px", float: "right"}}>
              - {author}
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

Quote.propTypes = {
    text: PropTypes.array.isRequired,
    author: PropTypes.string.isRequired,
};

export default Quote;
