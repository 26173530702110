import "../css/about.scss";
import {Image} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import travel from "../assets/images/travel.png";
import sandy from "../assets/images/sandy.png";
import birdStack from "../assets/images/bird_stack.png";
import Hero from "../components/Hero";
import Quote from "../components/Quote";
import ContactCallout from "../components/ContactCallout";
import TwoColumnImageRight from "../components/TwoColumnImageRight";

function About() {
    return (
        <>
            <Hero
                image={travel}
                smallTitle="ABOUT"
                title="About Efficient"
                body={[
                    "Let’s keep this simple.",
                    <br key="text1"/>,
                    "Efficient is a new way of organizing and managing documents and IP that enables anyone, "
                    +"anywhere to access the latest version of anything.",
                ]}
                col1="Whether ‘anything’ is a document, a proposal, a project or a picture. Whether it was updated last year or five minutes ago."
                col2={"No matter where it is now or where it’s likely to be going in the future, Efficient gets "
                +"information off personal desktops and out of employees’ heads to enable easy document retrieval and sharing."}
            />

            {/* Every Single Thing'*/}
            <Container fluid className="main yellow d-none d-md-block">
                <h1>Every. Single. Thing.</h1>
                <Row>
                    <Col
                        sm="6"
                        style={{borderRight: "2px solid gray", paddingRight: "60px"}}
                    >
                        <p>
                            Efficient ensures valuable knowledge is retained, updated, added to and securely filed.
                            It catalogues, indexes and keeps track of everything that is important to your business.
                            No matter how big your business is, or wants to be. Templates. Artwork. Manuals. Procedures.
                            If it’s a thing, and it’s your thing, and an important thing, Efficient will make it easy
                            to find and impossible to lose.
                        </p>
                        <p className="quoteCallout">
                            “Efficient will make it easy to find and impossible to loose.”
                        </p>
                    </Col>
                    <Col
                        sm="6"
                        style={{paddingLeft: "60px"}}
                    >
                        <p>
                            Efficient is a scalable system and automated master index designed to grow with you, even
                            as you outgrow people and buildings, change direction or restructure your operations.
                            It will transform the efficiency of your information handling.
                            Efficient came about when a very organised person found herself stuck in a virtual filing
                            room virtually knee deep in virtual paper, trying to sort the whole mess out. She had an
                            organisational epiphany, and the idea for Efficient was born – one automated index, one
                            system, one source of truth.
                        </p>
                    </Col>

                </Row>
            </Container>

            {/* Meet Sandy*/}
            <Container fluid className="main">
                <Row>
                    <Col md="1"/>
                    <Col md="4">
                        <Image
                            style={{
                                width: "100%",
                                maxWidth: "400px",
                                minWidth: "200px",
                                borderRadius: "20px",
                                marginTop: "20px",
                            }}
                            src={sandy}
                        />
                    </Col>
                    <Col md="6" >
                        <h1>The Efficient Guru</h1>
                        <h3>Sandy Parrott</h3>
                        <h2>CEO & Founder</h2>
                        <p>
                            As a Registered Architect and MBA, Sandy was put in charge of multi-million-dollar
                            developments and became increasingly involved in the business side of things.
                            She worked variously in design, construction, brand and project management for brands
                            such as KFC, Pizza Hut, Event Cinemas, Rydges and QT Hotels.
                        </p>
                        <p>
                            She completed an MBA at the University of New South Wales, while on a mission to transform
                            the structure of digital information. After ten years of streamlining systems and digital
                            assets, in 2020 Sandy launched Efficient to bring better document and IP management to
                            more businesses and organisations around the world.
                        </p>
                    </Col>
                    <Col md="1"/>
                </Row>
            </Container>

            {/* Efficient at work'*/}
            <Container fluid className="main yellow d-none d-md-block">
                <h1>Efficient at work</h1>
                <Row>
                    <Col
                        sm="6"
                        style={{borderRight: "2px solid gray", paddingRight: "60px"}}
                    >
                        <p>
                            Efficient is a transformational tool and its positive effects will touch every corner
                            of your business or organisation, in and out of the office.
                        </p>
                        <p className="quoteCallout">
                            “Efficient is a transformational tool”
                        </p>
                        <p>
                            HR people, for example, will have their onboarding process simplified because they
                            can always find and share the most up-to-date version of ever-changing induction packs.
                            Even when they’re working from home or commuting.
                        </p>
                    </Col>
                    <Col
                        sm="6"
                        style={{paddingLeft: "60px"}}
                    >
                        <p>
                            Your sales teams will confidently walk into their meetings knowing the client introduction
                            pack doesn’t contain embarrassing drafts or the wrong version. Project coordinators will
                            feel more relaxed knowing that potentially disastrous document mix-ups are now out of the question.
                        </p>
                    </Col>

                </Row>
            </Container>

            {/* Quote'*/}
            <Quote
                text={[
                    "For every ",
                    <span key="text1" className="redText">minute</span>,
                    " spent organizing, ",
                    <span key="text2" className="yellowText">an hour</span>, " is earned.",
                ]}
                author="Benjamin Franklin"
            />

            <TwoColumnImageRight
                smallTitle=""
                title="How do you get Efficient"
                body={[
                    "Right now, Efficient is a service customized for your business. ",
                    "We work with you and your team to look at how you’re currently handling your ",
                    "information and how best to implement the Efficient solution. Once the index ",
                    "and structure are in place, assets are managed with proprietary software, conceived, ",
                    "designed and developed by us. Efficient has brought order and structure to the information ",
                    "organisation of large, complex businesses such as KFC, Event Cinemas and Rydges Hotels, ",
                    "small businesses like Guthreys Coachlines and HD Building, and even the space scientists Saber Astronautics. ",
                    "It has transformed their operations, streamlined workflow, increased productivity and made them, well, more Efficient.",
                ]}
                col1Image={birdStack}
                link="/contact"
                buttonText="Find out how we can do the same for you"
                classes="main yellow"
            />
            <ContactCallout/>
        </>
    );
}

export default About;
