import "../css/about.scss";
import {Image, Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

function TwoColumnImageRight({
    smallTitle,
    title,
    body,
    col1Image,
    link,
    buttonText,
    classes, // main, pink, yellow,
}) {
    return (
        <>
            <Container fluid className={classes}>
                <Row>
                    <Col sm="7" >
                        {smallTitle ? <h5>{smallTitle}</h5> : <></>}
                        <h1>{title}</h1>
                        <p>{body}</p>
                        {
                            link
                                ? <p className="pt-4">
                                    <Button variant="danger" href={link}>
                                        {buttonText}
                                    </Button>
                                </p>
                                : <></>
                        }
                    </Col>
                    <Col sm="5">
                        <Image
                            style={{
                                width: "100%",
                                maxWidth: "600px",
                            }}
                            src={col1Image}
                        />
                    </Col>
                </Row>


            </Container>
        </>
    );
}

TwoColumnImageRight.propTypes = {
    smallTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.array.isRequired,
    link: PropTypes.string,
    col1Image: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    classes: PropTypes.string.isRequired,
};

export default TwoColumnImageRight;
