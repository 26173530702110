import "../css/about.scss";
import {Image, Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

function ThreeColumn({
    smallTitle,
    title,
    body,
    col1Image,
    col1Title,
    col1Text,
    col2Image,
    col2Title,
    col2Text,
    col3Image,
    col3Title,
    col3Text,
    link,
}) {
    return (
        <>
            <Container fluid className="main text-center">
                <Row>
                    <Col sm="2"/>
                    <Col sm="8" >
                        {smallTitle ? <h5>{smallTitle}</h5> : <></> }
                        {title? <h1>{title}</h1> : <></>}
                        {body ? <p>{body}</p> : <></>}
                    </Col>
                    <Col sm="2"/>
                </Row>
                <Row>
                    <Col sm="4">
                        <Image src={col1Image}/>
                        <h3>{col1Title}</h3>
                        <p>{col1Text}</p>
                    </Col>
                    <Col sm="4">
                        <Image src={col2Image}/>
                        <h3>{col2Title}</h3>
                        <p>{col2Text}</p>
                    </Col>
                    <Col sm="4">
                        <Image src={col3Image}/>
                        <h3>{col3Title}</h3>
                        <p>{col3Text}</p>
                    </Col>
                </Row>
                {
                    link
                        ? <Button variant="danger" href={link}>
              Learn More
                        </Button>
                        : <></>
                }

            </Container>
        </>
    );
}

ThreeColumn.propTypes = {
    smallTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.string,
    link: PropTypes.string,
    col1Image: PropTypes.string.isRequired,
    col1Title: PropTypes.string.isRequired,
    col1Text: PropTypes.string,
    col2Image: PropTypes.string.isRequired,
    col2Title: PropTypes.string.isRequired,
    col2Text: PropTypes.string,
    col3Image: PropTypes.string.isRequired,
    col3Title: PropTypes.string.isRequired,
    col3Text: PropTypes.string,
};

export default ThreeColumn;
