import "../css/about.scss";
import {Image, Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bird from "../assets/images/bird.png";

function ContactCallout() {
    return (
        <>
            <Container fluid style={{marginTop: "100px", marginBottom: "100px"}}className="blackCallout d-none d-md-block">
                <Row>
                    <Col sm="10">
                        <h5>CONTACT US</h5>
                        <h1 className="whiteText">Find out how to become an Efficient business.</h1>
                        <Button className="mt-5" variant="danger" href="/contact">
                            BOOK A FREE CONSULTATION
                        </Button>
                    </Col>
                    <Col sm="2">
                        <Image
                            style={{width: "100%"}}
                            src={bird}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactCallout;
