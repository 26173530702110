
import {Image, Button} from "react-bootstrap";
import footerBg from "../assets/images/footer-bg.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bird from "../assets/images/bird.png";
import heart from "../assets/images/heart.png";

function Footer() {
    return (
        <>
            {/* Desktop*/}
            <Container
                fluid
                className="d-none d-md-block"
            >
                <Row style={{
                    paddingTop: "350px",
                    paddingBottom: "25px",
                    backgroundImage: `url(${footerBg})`,
                }}>
                    <Col
                        style={{paddingLeft: "10%"}} md="4">
                        <Image
                            src={bird}
                            style={{
                                width: "70px",
                            }}
                        />
                        <p className="footerFont">Made with Birdcare <Image src={heart}/></p>
                    </Col>
                    <Col md="2">
                        <Button variant="link" href="/" className="whiteAnchor">Home</Button> <br/>
                        <Button variant="link" href="/process" className="whiteAnchor">Process</Button> <br/>
                        <Button variant="link" href="/certification" className="whiteAnchor">Certification</Button> <br/>
                    </Col>
                    <Col md="2">
                        <Button variant="link" href="/about" className="whiteAnchor">About</Button> <br/>
                        <Button variant="link" href="/services" className="whiteAnchor">Services</Button> <br/>
                        <Button variant="link" href="/contact" className="whiteAnchor">Contact</Button> <br/>
                    </Col>
                    <Col md="4">
                        <p className="whiteText"><b>Copy</b> by <b>FOLIO</b></p>
                        <p className="whiteText"><b>Website Design</b> by <b>White Rabbit</b></p>
                        <p className="whiteText">Copyright 2021, All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>

            {/* Mobile*/}
            <Container
                fluid
                className="d-block d-md-none"
                style={{


                    backgroundImage: `url(${footerBg})`,
                    backgroundPosition: "center"}}
            >
                <Image
                    src={bird}
                    style={{
                        width: "70px",
                    }}
                />
                <div
                    className="footerFont"
                    style={{padding: "0px"}}
                >Made with Birdcare <Image src={heart}/></div>


            </Container>

            <Container
                className="d-block d-md-none"
                style={{
                    height: "400px",
                    minWidth: "100%",
                    backgroundColor: "black",
                }}
            >
                <Button variant="link" href="/" className="whiteAnchor">Home</Button> <br/>
                <Button variant="link" href="/process" className="whiteAnchor">Process</Button> <br/>
                <Button variant="link" href="/certification" className="whiteAnchor">Certification</Button> <br/>
                <Button variant="link" href="/about" className="whiteAnchor">About</Button> <br/>
                <Button variant="link" href="/services" className="whiteAnchor">Services</Button> <br/>
                <Button variant="link" href="/contact" className="whiteAnchor">Contact</Button> <br/>
                <p className="whiteText"><b>Copy</b> by <b>FOLIO</b></p>
                <p className="whiteText"><b>Website Design</b> by <b>White Rabbit</b></p>
                <p className="whiteText">Copyright 2021, All Rights Reserved.</p>
            </Container>
        </>
    );
}

export default Footer;
