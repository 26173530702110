import React from "react";
import "./index.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Process from "./pages/Process";
import Services from "./pages/Services";
import Certification from "./pages/Certification";
import Contact from "./pages/Contact";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {createRoot} from "react-dom/client";

function Main() {
    return (
        <>
            <Header />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/process" element={<Process />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/certification" element={<Certification />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </Router>
            <Footer />
        </>
    );
}

const root = createRoot(document.getElementById("root"));
root.render(<Main />);
