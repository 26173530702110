import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import React, {useRef, useState} from "react";
import {Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Hero from "../components/Hero";
import ReCaptcha from "../components/ReCaptcha";
import contact from "../assets/images/contact.png";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const [verifyCaptcha, setVerifyCaptcha] = useState(() => {});
    const baseUrl = (
        window.location.href.includes("localhost")
        || window.location.href.includes("127.0.0.1")
    )
        ? "http://localhost:3001/contact"
        : (
            "https://api."
            + window.location.hostname
            + window.location.pathname
        );

    const emailInput = useRef(null);
    const onVerifyCaptcha = (verifyFunc) => {
        setVerifyCaptcha(() => verifyFunc);
    };
    const handleSubmit = async (event)=>{
        event.preventDefault();
        setIsSubmitting(true);
        await verifyCaptcha();
        if (!isCaptchaVerified) {
            toast.error("Captcha failed. Go away Robot", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.error("Captcha not verified");
            setIsSubmitting(false);
            return;
        }
        const data = new FormData(event.target);

        axios.post(baseUrl, {
            firstName: data.get("firstName"),
            website: data.get("companywebsite") ?? "",
            email: data.get("email"),
            message: data.get("message"),
        }).then( (response)=> {
            toast.success("Your email has been sent. We will be in touch shortly!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsSubmitting(false);
        }).catch(function(error) {
            console.log(error);
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <Hero
                image={contact}
                title="Contact us"
                body= {[
                    "We work with clients globally to organise information and digital assets,",
                    " making businesses more organised, efficient and scalable.",
                ]}
            />

            <Container
                className="blackCallout"
                style={{
                    width: "90%",
                }}
            >
                <h1 className="whiteText text-center">Tell us what you&apos;re thinking!</h1>
                <p className="whiteText pb-5 text-center">Add me to the Waitlist, Book a Consult, Message Us.</p>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm="12">
                            <Form.Group className="mb-3" controlId="formFirstName">
                                <Form.Control
                                    name="firstName"
                                    required
                                    type="text"
                                    className="form-control-lg"
                                    placeholder="*First Name"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formCompanyWebsite">
                                <Form.Control
                                    name="companywebsite"
                                    type="text"
                                    className="form-control-lg"
                                    placeholder="Company Website"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Control
                                    ref={emailInput}
                                    name="email"
                                    required
                                    type="email"
                                    className="form-control-lg"
                                    placeholder="*Email"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formText">
                                <Form.Label className="whiteText">Leave us a message</Form.Label>
                                <Form.Control name="message" as="textarea" rows={5} required />
                            </Form.Group>

                            <Row>
                                <Col sm="4">
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey="6LcEMBUpAAAAAPFrpIxk8eQCFatvhV15Piej5hXz"
                                    >
                                        <ReCaptcha
                                            setCaptchaVerified = {setCaptchaVerified}
                                            triggerVerify={onVerifyCaptcha}
                                        />
                                    </GoogleReCaptchaProvider>
                                </Col>
                                <Col sm="8">
                                    <Button
                                        disabled={isSubmitting}
                                        className="float-end"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Add me to the waitlist!
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <ToastContainer />
        </>
    );
}

export default Contact;
