import "../css/about.scss";
import {Image} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

function Hero({
    image,
    smallTitle,
    title,
    body,
    col1,
    col2,
}) {
    return (
        <>
            {/* Desktop*/}
            <Container fluid className="main d-none d-md-block">
                <Row className="text-center">
                    <Col sm="12"
                        style={{
                            height: "632px",
                            backgroundImage: `url(${image})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}>
                        <Row>
                            <Col sm="3"/>
                            <Col sm="6">
                                <h5>{smallTitle}</h5>
                                <h1>{title}</h1>
                                <p className="lightTextBg">{body}</p>
                            </Col>
                            <Col sm="3"/>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col sm="6">
                        <p>{col1}</p>
                    </Col>
                    <Col sm="6">
                        <p>{col2}</p>
                    </Col>
                </Row>
            </Container>

            {/* Hero Mobile*/}
            <Container
                fluid
                className="main d-block d-md-none"
                style={{borderBottom: "1px solid lightgray"}}
            >
                <Row className="text-center">
                    <Col sm="12">
                        <h5>{smallTitle}</h5>
                        <h1>{title}</h1>
                        <p>{body}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Image
                            src={image}
                            style={{width: "100%"}}
                        />
                    </Col>
                </Row>
                {
                    (col1 && col2)
                        ? <Row>
                            <Col sm="6">
                                <p>{col1}</p>
                            </Col>
                            <Col sm="6">
                                <p>{col2}</p>
                            </Col>
                        </Row>
                        : <></>
                }
            </Container>

        </>
    );
}

Hero.propTypes = {
    image: PropTypes.string.isRequired,
    smallTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.array.isRequired,
    col1: PropTypes.string,
    col2: PropTypes.string,
};

export default Hero;
