
import Container from "react-bootstrap/Container";
import conveyor from "../assets/images/conveyor.png";
import ContactCallout from "../components/ContactCallout";
import Hero from "../components/Hero";
import ThreeColumn from "../components/ThreeColumn";
import getEfficient from "../assets/images/get_efficient.png";
import maintenance from "../assets/images/maintenance.png";
import docCreation from "../assets/images/document_creation.png";

function Services() {
    return (
        <Container fluid className="h-100">
            <Hero
                image={conveyor}
                smallTitle="SERVICES"
                title="Efficient Services"
                body={[
                    "Everything about Efficient is designed to make your business more organised.",
                ]}
            />

            <ThreeColumn
                title="Our Services"
                body="We can provide the following services on a do-and-charge-basis."
                col1Image={getEfficient}
                col1Title="1. Get Efficient"
                col1Text={"This is our foundation service where we design your "
                          +"Efficient index, structure your folders and documents, and train your team."}
                col2Image={maintenance}
                col2Title="2. Efficient Maintenance"
                col2Text={"We make it easy and cost-effective to maintain your Efficient system using smart "
                        +"(SaaS) software and monthly support from Efficient Gurus."}
                col3Image={docCreation}
                col3Title="3. Document Creation"
                col3Text={"When you need to create a new template, procedure or manual from scratch, we can "
                        +"design something logical that your team can pick up and start using."}
            />

            <ContactCallout/>

        </Container>
    );
}

export default Services;
