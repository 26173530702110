
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import conveyor from "../assets/images/conveyor.png";
import one from "../assets/images/1.png";
import two from "../assets/images/2.png";
import three from "../assets/images/3.png";
import four from "../assets/images/4.png";
import five from "../assets/images/5.png";
import six from "../assets/images/6.png";
import ContactCallout from "../components/ContactCallout";
import Hero from "../components/Hero";
import Quote from "../components/Quote";
import TwoColumnImageLeft from "../components/TwoColumnImageLeft";

function Process() {
    return (
        <Container fluid className="h-100">
            <Hero
                image={conveyor}
                smallTitle="PROCESS"
                title="Our Process"
                body={[
                    "Streamlined document organization for busy business owners",
                ]}
            />

            <Quote
                text={[
                    "With ",
                    <span key="text1" className="redText">organization</span>,
                    " comes ",
                    <span key="text2" className="yellowText">empowerment</span>,
                    ".",
                ]}
                author="Lynda Peterson"
            />

            <Container fluid className="text-center mb-5">
                <Row>
                    <Col sm="3"/>
                    <Col sm="6">
                        <h1>The process we rely on</h1>
                        <p>
                            Everything about Efficient lives up to its name. Including implementation.
                             It’s an easy-to-follow process that keeps your touchpoints to a minimum and reduces the time needed to
                             get all your documents and digital assets organised into a findable, manageable structure.
                        </p>
                    </Col>
                    <Col sm="3"/>
                </Row>
            </Container>
            <TwoColumnImageLeft
                title="Understanding your business"
                body={[
                    "We meet with the business or organisation head to get a first-hand overview of your structure, pain points",
                    " and potential growth areas. Then we create a draft index, a blueprint for your Efficient system.",
                ]}
                col1Image={one}
                classes="main pink mb-5"
            />

            <TwoColumnImageLeft
                title="Draft index, feedback"
                body={[
                    "We present the proposed master index to your leadership team to ensure it captures the overall business and",
                    " departments that need to be covered by Efficient.",
                ]}
                col1Image={two}
                classes="main yellow mb-5"
            />

            <TwoColumnImageLeft
                title="Checking in with stakeholders"
                body={[
                    "With the draft master index agreed, we then meet with key department leaders or subject matter experts to",
                    " add specific department functions within Efficient, as well as the templates, processes and policies that are ‘must haves’.",
                ]}
                col1Image={three}
                classes="main pink mb-5"
            />

            <TwoColumnImageLeft
                title="Complete information grab"
                body={[
                    "We take a copy of all existing information assets from throughout the business. We look at the various servers that hold",
                    " information, projects that represent ‘best practice’, and files from individual employees’ desktops – even old hard copies",
                    " that are still everyday practice.",
                ]}
                col1Image={four}
                classes="main yellow mb-5"
            />

            <TwoColumnImageLeft
                title="Organising everything"
                body={[
                    "This is the hardest but most rewarding stage. We begin to order, shape and massage the information.",
                    " We can start in whichever area of the business most urgently needs sorting.",
                    "We help each of your subject matter experts to produce their best version of documents that are either missing,",
                    " outdated or non-existent. Then we begin to populate the system. Every item is categorised, double-ups are removed,",
                    " best versions are identified, then we file everything in the Efficient numbered catalogue system.",
                ]}
                col1Image={five}
                classes="main pink mb-5"
            />

            <TwoColumnImageLeft
                title="Efficient deployment and training"
                body={["Efficient, by now, is a living, breathing system – your company’s sole, reliable, self-maintaining ‘source of truth’.",
                    " Your team complete a simple ten-minute video training session, consolidated with a quiz, so they can start using the",
                    " system straight away.",
                ]}
                col1Image={six}
                classes="main yellow mb-5"
            />

            <ContactCallout/>

        </Container>
    );
}

export default Process;
